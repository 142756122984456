import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import {
  Box,
  DialogWithCloseButton,
  SvgIcon,
  Button,
  ErrorAlert,
} from "@lib/ui/core"

import { ReactComponent as StarIcon } from "@assets/icons/star.svg"

import { createQuiz } from "@services/exercise"

const CustomChip = styled(Box)`
  display: inline-block;
  background-color: ${({ bgcolor }) => bgcolor};
  color: #ffffff;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 25px;
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
`

const TopicChip = styled(Box)`
  display: inline-block;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 25px;
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
`

const CircleChip = styled(Box)`
  display: inline-block;
  background-color: #0f1d65;
  color: #ffffff;
  padding: 8px;
  font-size: 14px;
  border-radius: 50%;
  height: 36px;
  width: 36px;
`

const StyledButton = styled(Button)`
  background-color: #007bff !important;
  padding: 0 1rem;
  border-radius: 14px;
  color: #fff !important;
  width: 10rem;
  height: 2rem;
`

// eslint-disable-next-line arrow-body-style
const ConfirmModal = ({ open, handleClose, quizParam, topicText }) => {
  const [alert, setAlert] = useState(false)

  const closeAlert = () => {
    setAlert(false)
  }

  const {
    name,
    mode,
    grade,
    type,
    level,
    lang,
    duration,
    qty,
    selectedTopic,
    selectedCategory,
    includedKnowledge,
  } = quizParam

  const gradeText = {
    "F.1": "中一",
    "F.2": "中二",
    "F.3": "中三",
    "F.4": "中四",
    "F.5": "中五/六",
  }

  const typeText = {
    mc: "多項選擇題 (MC)",
    lq: "長問題 (LQ)",
  }
  const langText = {
    E: "EN",
    C: "中",
  }

  const modeText = {
    single_topic: "單一課題",
    multiple_topic: "綜合課題",
    quiz: "測驗模式",
    exam: "考試模式",
  }

  const start = async () => {
    const formattedQuizParam = {
      name: name && name !== "" ? name : modeText[mode],
      mode,
      level,
      lang,
      grade,
      question_type: type || undefined,
      duration,
      qty: qty || undefined,
      topic_ids: selectedTopic,
      category_ids: selectedCategory,
      is_include_prev_knowledge: includedKnowledge,
    }
    try {
      const data = await createQuiz({
        quizParam: formattedQuizParam,
      })
      const quizId = data.item.id
      navigate(`/exercise/process?quiz_id=${quizId}`)
    } catch (e) {
      setAlert(true)
    }
  }
  return (
    <Box>
      <DialogWithCloseButton
        onClose={handleClose}
        open={open}
        title="建立練習"
        tips={"請準備好紙、筆、計算機，\n認真開始練習，不要自欺欺人"}
        subTitle={name}
      >
        <Box
          p={1}
          display="flex"
          alignItems="center"
          width="100%"
          flexWrap="wrap"
        >
          <Box m={1}>
            <CustomChip bgcolor="#9D90F3" whiteSpace="nowrap">
              {gradeText[grade]}
            </CustomChip>
          </Box>
          {type && (
            <Box m={1}>
              <CustomChip bgcolor="#81C0FF" whiteSpace="nowrap">
                {typeText[type]}
              </CustomChip>
            </Box>
          )}
          {qty && (
            <Box m={1}>
              <CustomChip bgcolor="#FE96A4" whiteSpace="nowrap">
                {qty}題
              </CustomChip>
            </Box>
          )}
          {duration && (
            <Box m={1}>
              <CustomChip bgcolor="#FFCA5E" whiteSpace="nowrap">
                {duration}分鐘時限
              </CustomChip>
            </Box>
          )}
          <Box m={1}>
            <CustomChip bgcolor="#68E8C5">
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  alignItems="center"
                  mx={1}
                  whiteSpace="nowrap"
                >
                  難度
                </Box>
                <Box display="flex" alignItems="center" mx={1}>
                  <SvgIcon component={StarIcon} fontSize="1rem" color="#fff" />
                </Box>
                {level >= 2 && (
                  <Box display="flex" alignItems="center" mx={1}>
                    <SvgIcon
                      component={StarIcon}
                      fontSize="1rem"
                      color="#fff"
                    />
                  </Box>
                )}
                {level >= 3 && (
                  <Box display="flex" alignItems="center" mx={1}>
                    <SvgIcon
                      component={StarIcon}
                      fontSize="1rem"
                      color="#fff"
                    />
                  </Box>
                )}
              </Box>
            </CustomChip>
          </Box>
          <Box m={1}>
            <CircleChip align="center">{langText[lang]}</CircleChip>
          </Box>
        </Box>
        <Box p={1}>
          {topicText &&
            topicText.map(({ topic, category }) => (
              <Box pb={2}>
                <Box my={0.5}>
                  <TopicChip
                    bgcolor="#007BFF"
                    whiteSpace="nowrap"
                    fontSize="16px"
                    py={1}
                    px={2}
                    bold
                  >
                    {topic.name.replace("-", String.fromCharCode(0x2011))}
                  </TopicChip>
                </Box>
                {category.map((cat) => (
                  <Box my={0.5}>
                    <TopicChip
                      bgcolor="#007BFF"
                      whiteSpace="nowrap"
                      fontSize="12px"
                      py={0.5}
                      px={2}
                    >
                      {cat.name}
                    </TopicChip>
                  </Box>
                ))}
              </Box>
            ))}
        </Box>
        <Box p={1} width="100%" display="flex" justifyContent="center">
          <StyledButton onClick={() => start()}>確認</StyledButton>
        </Box>
      </DialogWithCloseButton>
      <ErrorAlert open={alert} handleClose={closeAlert}>
        系統出錯，請稍後再試。
      </ErrorAlert>
    </Box>
  )
}

export default ConfirmModal
