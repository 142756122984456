import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {
  Box,
  DialogWithCloseButton,
  TextField,
  Button,
  Typography,
  SvgIcon,
  Grid,
  Select,
  MenuItem,
  Chip,
  AppBar,
  Toolbar,
  Snackbar,
} from "@lib/ui/core"

import CloseIcon from "@material-ui/icons/Close"
import { ReactComponent as StarIcon } from "@assets/icons/star.svg"

import { getTopic } from "@services/exercise"

import UserGuide from "@components/UserGuide"

import BottomBar from "./BottomBar"

import ConfirmModal from "./ConfirmModal"

const StyledTextField = styled(TextField)`
  .MuiFilledInput-root {
    background-color: ${({ bgcolor }) => bgcolor};
    border-radius: 22px;
  }
  .MuiFilledInput-input {
    padding: 8px 16px;
    text-align: center;
  }
  .MuiInputBase-input {
    height: 1.5rem;
    font-size: 20px;
    color: #fff;
  }
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`

const SearchTextField = styled(TextField)`
  .MuiFilledInput-root {
    background-color: ${({ bgcolor }) => bgcolor};
    border-radius: 22px;
  }
  .MuiFilledInput-input {
    padding: 8px 16px;
  }
  .MuiInputBase-input {
    height: 1.5rem;
    font-size: 20px;
    color: #4d4d4d;
  }
`

const StyledDropDown = styled(TextField)`
  .MuiFilledInput-root {
    background-color: ${({ bgcolor }) => bgcolor};
    border-radius: 22px;
  }
  .MuiFilledInput-input {
    padding: 8px 32px 8px 16px;
    text-align: center;
  }
  .MuiInputBase-input {
    height: 1rem;
    color: #fff;
  }
  .MuiSelect-iconFilled {
    color: #fff;
  }
  .MuiMenu-paper {
    border-radius: 22px;
  }
  .MuiPopover-paper {
    border-radius: 22px;
    background-color: ${({ bgcolor }) => bgcolor};
  }
`
const CustomChip = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected, selectedColor }) =>
    selected ? selectedColor || "#007bff" : "#cecece"};
  color: ${({ selected }) => (selected ? "#ffffff" : "#4d4d4d")};
  padding: 8px 16px;
  font-size: ${({ fontSize }) => fontSize};
  border-radius: 25px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
`

const TopicChip = styled(Box)`
  display: inline-block;
  background-color: ${({ selected, selectedColor }) =>
    selected ? selectedColor || "#007bff" : "#cecece"};
  color: ${({ selected }) => (selected ? "#ffffff" : "#4d4d4d")};
  font-size: ${({ fontSize }) => fontSize};
  border-radius: 25px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
`

const CircleChip = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) => (selected ? "#0F1D65" : "#cecece")};
  color: ${({ selected }) => (selected ? "#ffffff" : "#4d4d4d")};
  padding: 8px;
  font-size: ${({ fontSize }) => fontSize};
  border-radius: 50%;
  cursor: pointer;
`

const ViewOnlyChip = styled(Box)`
  display: inline-flex;
  background-color: ${({ bgcolor }) => bgcolor};
  color: #ffffff;
  font-size: ${({ fontSize }) => fontSize};
  border-radius: 25px;
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
`

const GrayBar = styled.div`
  background-color: #cecece;
  height: 3px;
  width: 100%;
`
const StyledButton = styled(Button)`
  background-color: #007bff !important;
  padding: 0.2rem 1rem;
  border-radius: 14px;
  color: #fff !important;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`

const OverfloWBox = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
`

const SelectPanelContainer = styled(Box)`
  height: calc(100vh - 170px);
`

const disabledTopic = [1, 2, 8, 9, 22]

// eslint-disable-next-line arrow-body-style
const SingleTopic = ({ mode, resetMode, setMode }) => {
  const [fullTopicList, setFullTopicList] = useState([])
  const [orginialTopicList, setOrginialTopicList] = useState([])
  const [topicList, setTopicList] = useState([])

  const [name, setName] = useState("")
  const [topicKeyword, setTopicKeyword] = useState("")

  const [grade, setGrade] = useState("F.1")
  const [type, setType] = useState("lq")
  const [level, setLevel] = useState(1)
  const [lang, setLang] = useState("E")
  const [duration, setDuration] = useState(30)
  const [qty, setQty] = useState(15)
  const [includedKnowledge, setIncludedKnowledge] = useState(false)

  const [selectedTopic, setSelectedTopic] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])

  const [openConfirm, setOpenConfirm] = useState(false)

  const [showWaiting, setShowWaiting] = useState(false)

  const fetchData = async () => {
    const result = await getTopic({ grade })
    setTopicList(result.items)
    setOrginialTopicList(result.items)
    const fullresult = await getTopic({})
    setFullTopicList(fullresult.items)
  }

  const modeConfig = {
    single_topic: {
      mc: {
        qty: {
          min: 8,
          max: 30,
        },
      },
      lq: {
        qty: {
          min: 8,
          max: 15,
        },
      },
      ableSelectType: true,
      ableSelectQty: true,
      ableSelectDuration: false,
      ableMultipleTopic: false,
      ableIncludedKnowledge: false,
    },
    multiple_topic: {
      mc: {
        qty: {
          min: 10,
          max: 50,
        },
      },
      lq: {
        qty: {
          min: 10,
          max: 30,
        },
      },
      ableSelectType: true,
      ableSelectQty: true,
      ableSelectDuration: false,
      ableMultipleTopic: true,
      ableIncludedKnowledge: false,
    },
    quiz: {
      mc: {
        duration: {
          min: 15,
          max: 180,
        },
      },
      lq: {
        duration: {
          min: 15,
          max: 180,
        },
      },
      fixedQty: {
        Lv1: {
          "F.1": { mc: "LQ:5 + MC:10", lq: "LQ:5 + MC:10" },
          "F.2": { mc: "LQ:5 + MC:10", lq: "LQ:5 + MC:10" },
          "F.3": { mc: "LQ:5 + MC:10", lq: "LQ:5 + MC:10" },
          "F.4": { mc: "LQ:5 + MC:10", lq: "LQ:5 + MC:10" },
          "F.5": { mc: "LQ:5 + MC:15", lq: "LQ:5 + MC:15" },
        },
        Lv2: {
          "F.1": { mc: "LQ:6 + MC:10", lq: "LQ:6 + MC:10" },
          "F.2": { mc: "LQ:6 + MC:10", lq: "LQ:6 + MC:10" },
          "F.3": { mc: "LQ:6 + MC:10", lq: "LQ:6 + MC:10" },
          "F.4": { mc: "LQ:6 + MC:10", lq: "LQ:6 + MC:10" },
          "F.5": { mc: "LQ:6 + MC:15", lq: "LQ:6 + MC:15" },
        },
        Lv3: {
          "F.1": { mc: "LQ:6 + MC:10", lq: "LQ:6 + MC:10" },
          "F.2": { mc: "LQ:6 + MC:10", lq: "LQ:6 + MC:10" },
          "F.3": { mc: "LQ:6 + MC:10", lq: "LQ:6 + MC:10" },
          "F.4": { mc: "LQ:6 + MC:10", lq: "LQ:6 + MC:10" },
          "F.5": { mc: "LQ:6 + MC:15", lq: "LQ:6 + MC:15" },
        },
      },
      ableSelectType: false,
      ableSelectQty: false,
      ableSelectDuration: true,
      ableMultipleTopic: true,
      ableIncludedKnowledge: false,
    },
    exam: {
      mc: {
        duration: {
          min: 15,
          max: 180,
        },
      },
      lq: {
        duration: {
          min: 15,
          max: 180,
        },
      },
      fixedQty: {
        Lv1: {
          "F.1": { mc: "MC:20", lq: "LQ:12" },
          "F.2": { mc: "MC:20", lq: "LQ:14" },
          "F.3": { mc: "MC:25", lq: "LQ:15" },
          "F.4": { mc: "MC:35", lq: "LQ:16" },
          "F.5": { mc: "MC:45", lq: "LQ:18" },
        },
        Lv2: {
          "F.1": { mc: "MC:20", lq: "LQ:12" },
          "F.2": { mc: "MC:20", lq: "LQ:14" },
          "F.3": { mc: "MC:25", lq: "LQ:15" },
          "F.4": { mc: "MC:35", lq: "LQ:16" },
          "F.5": { mc: "MC:45", lq: "LQ:18" },
        },
        Lv3: {
          "F.1": { mc: "MC:20", lq: "LQ:12" },
          "F.2": { mc: "MC:20", lq: "LQ:14" },
          "F.3": { mc: "MC:25", lq: "LQ:15" },
          "F.4": { mc: "MC:35", lq: "LQ:16" },
          "F.5": { mc: "MC:45", lq: "LQ:18" },
        },
      },
      ableSelectType: true,
      ableSelectQty: false,
      ableSelectDuration: true,
      ableMultipleTopic: true,
      ableIncludedKnowledge: true,
    },
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleChangeGrade = async (newGrade) => {
    setGrade(newGrade)
    const result = await getTopic({ grade: newGrade })
    setTopicList(result.items)
    setOrginialTopicList(result.items)
  }

  const handleClickTopic = (topic) => {
    const topicCategoryId = topic.category.map((cat) => cat.id)
    if (topicCategoryId.every((each) => selectedCategory.includes(each))) {
      const newCategory = selectedCategory.filter(
        (ele) => !topicCategoryId.includes(ele)
      )
      const newTopic = selectedTopic.filter((ele) => ele !== topic.id)
      setSelectedTopic([...newTopic])
      setSelectedCategory([...newCategory])
    } else {
      const newCategory = selectedCategory.filter(
        (ele) => !topicCategoryId.includes(ele)
      )
      if (!selectedTopic.includes(topic.id)) {
        setSelectedTopic([...selectedTopic, topic.id])
      }
      setSelectedCategory([...newCategory, ...topicCategoryId])
    }
  }

  const handleDeleteTopic = (topic) => {
    const topicCategoryId = topic.category.map((cat) => cat.id)
    const newCategory = selectedCategory.filter(
      (ele) => !topicCategoryId.includes(ele)
    )
    const newTopic = selectedTopic.filter((ele) => ele !== topic.id)
    setSelectedTopic([...newTopic])
    setSelectedCategory([...newCategory])
  }

  const handleClickCategory = (topic, id) => {
    if (!selectedCategory.includes(id)) {
      const foundTopic = selectedTopic.find((ele) => ele === topic.id)
      if (!foundTopic) {
        setSelectedTopic([...selectedTopic, topic.id])
      }
      setSelectedCategory([...selectedCategory, id])
    } else {
      const newCategory = selectedCategory.filter((ele) => ele !== id)
      const topicCategory = topic.category.map((cat) => cat.id)
      if (topicCategory.every((each) => !newCategory.includes(each))) {
        const newTopic = selectedTopic.filter((ele) => ele !== topic.id)
        setSelectedTopic([...newTopic])
      }
      setSelectedCategory([...newCategory])
    }
  }

  const checkSubTopic = (topic, keyword) => {
    const subTopicId = topic.category.map((cat) => cat.name.toUpperCase())
    let found = false
    subTopicId.map((sub) => {
      if (sub.includes(keyword.toUpperCase())) {
        found = true
      }
    })
    return found
  }

  const handleUpdateKeyword = (keyword) => {
    setTopicKeyword(keyword)
    if (keyword && keyword !== "") {
      const filteredList = fullTopicList.filter(
        (ele) =>
          ele.name.toUpperCase().includes(keyword.toUpperCase()) ||
          checkSubTopic(ele, keyword)
      )
      setTopicList([...filteredList])
    } else {
      setTopicList([...orginialTopicList])
    }
  }

  const handleUpdateIncludedKnowledge = () => {
    if (includedKnowledge) {
      setIncludedKnowledge(false)
    } else {
      setIncludedKnowledge(true)
    }
  }

  const checkQty = (questionType) => {
    if (modeConfig[mode].ableSelectQty) {
      if (qty < modeConfig[mode][questionType].qty.min) {
        setQty(modeConfig[mode][questionType].qty.min)
      } else if (qty > modeConfig[mode][questionType].qty.max) {
        setQty(modeConfig[mode][questionType].qty.max)
      }
    }
  }

  const checkDuration = (questionType) => {
    if (modeConfig[mode].ableSelectDuration) {
      if (duration < modeConfig[mode][questionType].duration.min) {
        setDuration(modeConfig[mode][questionType].duration.min)
      } else if (duration > modeConfig[mode][questionType].duration.max) {
        setDuration(modeConfig[mode][questionType].duration.max)
      }
    }
  }

  const handleChangeType = (questionType) => {
    setType(questionType)
    checkQty(questionType)
  }

  const topicText = []
  selectedTopic.map((topicId) => {
    const matchTopic = fullTopicList.find((ele) => ele.id === topicId)
    const matchCategory = []
    selectedCategory.map((ele) => {
      const findCategory = matchTopic.category.find((cat) => cat.id === ele)
      if (findCategory) matchCategory.push(findCategory)
    })
    topicText.push({
      topic: matchTopic,
      category: matchCategory,
    })
  })
  return (
    <SelectPanelContainer>
      <Box display="flex" justifyContent="center" height="20%">
        <Box py={1} flexGrow={1}>
          <Box display="flex" justifyContent="center" color="#9D90F3">
            <Typography noWrap>年級</Typography>
          </Box>
          <Box py={1}>
            <GrayBar />
          </Box>
          <Box display="flex" justifyContent="center">
            <StyledDropDown
              select
              // InputProps={{
              //   disableUnderline: true,
              // }}
              bgcolor="#9D90F3"
              width="7rem"
              height="2.5rem"
              value={grade}
              onChange={(e) => handleChangeGrade(e.target.value)}
            >
              <MenuItem value="F.1">中一</MenuItem>
              <MenuItem value="F.2">中二</MenuItem>
              <MenuItem value="F.3">中三</MenuItem>
              <MenuItem value="F.4">中四</MenuItem>
              <MenuItem value="F.5">中五/六</MenuItem>
            </StyledDropDown>
          </Box>
        </Box>
        <Box py={1} flexGrow={2}>
          <Box display="flex" justifyContent="center" color="#81C0FF">
            <Typography noWrap>題目模式</Typography>
          </Box>
          <Box py={1}>
            <GrayBar />
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            <CustomChip
              fontSize="20px"
              width="50%"
              height="2.5rem"
              mx={1}
              align="center"
              selected={modeConfig[mode].ableSelectType && type === "lq"}
              onClick={() => handleChangeType("lq")}
              selectedColor="#81C0FF"
              disabled={!modeConfig[mode].ableSelectType}
              whiteSpace="nowrap"
            >
              {mode === "exam" ? "Paper 1" : "長問題 (LQ)"}
            </CustomChip>
            <CustomChip
              fontSize="20px"
              width="50%"
              height="2.5rem"
              mx={1}
              align="center"
              selected={modeConfig[mode].ableSelectType && type === "mc"}
              onClick={() => handleChangeType("mc")}
              selectedColor="#81C0FF"
              disabled={!modeConfig[mode].ableSelectType}
              whiteSpace="nowrap"
            >
              {mode === "exam" ? "Paper 2" : "多項選擇題 (MC)"}
            </CustomChip>
          </Box>
        </Box>
        <Box py={1} flexGrow={modeConfig[mode].ableSelectQty ? 1 : 2}>
          <Box display="flex" justifyContent="center" color="#FE96A4">
            <Typography noWrap>題數</Typography>
          </Box>
          <Box py={1}>
            <GrayBar />
          </Box>
          <Box display="flex" justifyContent="center">
            <StyledTextField
              type="tel"
              // InputProps={{
              //   inputProps: {
              //     max: 30,
              //     min: 15,
              //   },
              //   disableUnderline: true,
              // }}
              width={modeConfig[mode].ableSelectQty ? "5rem" : "12rem"}
              height="2.5rem"
              bgcolor="#fe96a4"
              value={
                modeConfig[mode].ableSelectQty
                  ? qty
                  : modeConfig[mode].fixedQty[`Lv${level}`][grade][type]
              }
              onChange={(e) => setQty(e.target.value)}
              onBlur={() => checkQty(type)}
              disabled={!modeConfig[mode].ableSelectQty}
            />
          </Box>
        </Box>
        <Box py={1} flexGrow={1}>
          <Box display="flex" justifyContent="center" color="#FFCA5E">
            <Typography noWrap>時限(分鐘)</Typography>
          </Box>
          <Box py={1}>
            <GrayBar />
          </Box>
          <Box display="flex" justifyContent="center">
            <StyledTextField
              type="tel"
              InputProps={{
                inputProps: {
                  max: 30,
                  min: 15,
                },
                disableUnderline: true,
              }}
              width="5rem"
              height="2.5rem"
              bgcolor="#FFCA5E"
              value={modeConfig[mode].ableSelectDuration ? duration : ""}
              onChange={(e) => setDuration(e.target.value)}
              onBlur={() => checkDuration(type)}
              disabled={!modeConfig[mode].ableSelectDuration}
            />
          </Box>
        </Box>
        <Box py={1} flexGrow={1}>
          <Box display="flex" justifyContent="center" color="#68E8C5">
            <Typography>難度</Typography>
          </Box>
          <Box py={1}>
            <GrayBar />
          </Box>
          <Box display="flex" justifyContent="center" height="2.5rem">
            <Box
              px={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SvgIcon
                component={StarIcon}
                fontSize="2rem"
                color={level >= 1 ? "#68E8C5" : "#E6E6E6"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setLevel(1)}
              />
            </Box>
            <Box
              px={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SvgIcon
                component={StarIcon}
                fontSize="2rem"
                color={level >= 2 ? "#68E8C5" : "#E6E6E6"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setLevel(2)}
              />
            </Box>
            <Box
              px={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SvgIcon
                component={StarIcon}
                fontSize="2rem"
                color={level >= 3 ? "#68E8C5" : "#E6E6E6"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setLevel(3)}
              />
            </Box>
          </Box>
        </Box>
        <Box py={1} flexGrow={1}>
          <Box display="flex" justifyContent="center" color="#0F1D65">
            <Typography>題目語言</Typography>
          </Box>
          <Box py={1}>
            <GrayBar />
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            <CircleChip
              fontSize="12px"
              mx={1}
              align="center"
              onClick={() => setLang("E")}
              height="2.5rem"
              width="2.5rem"
              selected={lang === "E"}
            >
              EN
            </CircleChip>
            <CircleChip
              fontSize="12px"
              mx={1}
              align="center"
              onClick={() => setShowWaiting(true)}
              height="2.5rem"
              width="2.5rem"
              selected={lang === "C"}
            >
              中
            </CircleChip>
            <Snackbar
              open={showWaiting}
              onClose={() => setShowWaiting(false)}
              message="中文版本，快將推出，敬請留意！"
              autoHideDuration={3000}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" height="90%" pb={5}>
        <Box width="65%" p={1} m={1} maxHeight="100%">
          <Box
            color="#007bff"
            display="flex"
            alignItems="center"
            mb={1}
            height="10%"
          >
            <Box color="#007bff" fontSize="25px" fontWeight="900">
              選擇課題：
            </Box>
            <SearchTextField
              InputProps={{
                disableUnderline: true,
              }}
              placeholder="搜尋課題"
              bgcolor="#E6E6E6"
              width="10rem"
              value={topicKeyword}
              onChange={(e) => handleUpdateKeyword(e.target.value)}
            />
            {modeConfig[mode].ableIncludedKnowledge && (
              <CustomChip
                fontSize="20px"
                height="2.5rem"
                mx={1}
                selected={includedKnowledge}
                onClick={() => handleUpdateIncludedKnowledge()}
                selectedColor="#007BFF"
              >
                包含已有知識
              </CustomChip>
            )}
          </Box>
          {topicList.length > 0 && (
            <OverfloWBox height="90%">
              <Grid container spacing={2}>
                {topicList.map((topic) => (
                  <Grid item xs={6}>
                    <Box pb={2}>
                      <Box py={0.5}>
                        <TopicChip
                          bold
                          fontSize="16px"
                          py={1}
                          px={2}
                          onClick={() => {
                            if (
                              !(
                                !modeConfig[mode].ableMultipleTopic &&
                                selectedTopic.length > 0 &&
                                !selectedTopic.includes(topic.id)
                              ) &&
                              !disabledTopic.includes(topic.id)
                            )
                              handleClickTopic(topic)
                          }}
                          selected={selectedTopic.includes(topic.id)}
                          disabled={
                            (!modeConfig[mode].ableMultipleTopic &&
                              selectedTopic.length > 0 &&
                              !selectedTopic.includes(topic.id)) ||
                            disabledTopic.includes(topic.id)
                          }
                        >
                          {topic.name.replace("-", String.fromCharCode(0x2011))}
                        </TopicChip>
                      </Box>
                      {topic.category.length > 0 &&
                        topic.category.map((category) => (
                          <Box py={0.5}>
                            <TopicChip
                              fontSize="12px"
                              py={0.5}
                              px={2}
                              onClick={() => {
                                if (
                                  !(
                                    !modeConfig[mode].ableMultipleTopic &&
                                    selectedTopic.length > 0 &&
                                    !selectedTopic.includes(topic.id)
                                  ) &&
                                  !disabledTopic.includes(topic.id)
                                )
                                  handleClickCategory(topic, category.id)
                              }}
                              selected={selectedCategory.includes(category.id)}
                              disabled={
                                (!modeConfig[mode].ableMultipleTopic &&
                                  selectedTopic.length > 0 &&
                                  !selectedTopic.includes(topic.id)) ||
                                disabledTopic.includes(topic.id)
                              }
                            >
                              {category.name}
                            </TopicChip>
                          </Box>
                        ))}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </OverfloWBox>
          )}
        </Box>
        <Box
          bgcolor="#F7F8F8"
          boxShadow={10}
          p={1}
          m={1}
          width="33.3%"
          maxHeight="100%"
        >
          <Box
            fontSize="25px"
            color="#007BFF"
            mb={1}
            height="10%"
            fontWeight="900"
          >
            已選擇課題：
          </Box>
          <OverfloWBox width="100%" height="90%">
            <Grid container spacing={2}>
              {topicText.map(({ topic, category }) => (
                <Grid item xs={12}>
                  <Box pb={2}>
                    <Box pb={0.5}>
                      <ViewOnlyChip
                        bgcolor="#007BFF"
                        bold
                        fontSize="16px"
                        display="flex"
                        alignItems="center"
                        px={2}
                        py={1}
                      >
                        <Box display="flex" alignItems="center">
                          {topic.name.replace("-", String.fromCharCode(0x2011))}
                        </Box>
                        <Box
                          ml={1}
                          display="flex"
                          alignItems="center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleDeleteTopic(topic)
                          }}
                        >
                          <SvgIcon component={CloseIcon} fontSize="16px" />
                        </Box>
                      </ViewOnlyChip>
                    </Box>
                    {category.map((cat) => (
                      <Box py={0.5}>
                        <ViewOnlyChip
                          bgcolor="#007BFF"
                          fontSize="12px"
                          display="flex"
                          alignItems="center"
                          px={2}
                          py={0.5}
                        >
                          <Box display="flex" alignItems="center">
                            {cat.name}
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            ml={1}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickCategory(topic, cat.id)}
                          >
                            <SvgIcon component={CloseIcon} fontSize="12px" />
                          </Box>
                        </ViewOnlyChip>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </OverfloWBox>
        </Box>
      </Box>
      {/* <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={1}
        height="10%"
      >
        <StyledButton
          onClick={() => setOpenConfirm(true)}
          disabled={selectedTopic.length === 0}
          width="7.5rem"
        >
          開始
        </StyledButton>
      </Box> */}
      <BottomBar
        mode={mode}
        resetMode={resetMode}
        name={name}
        setName={setName}
        setMode={setMode}
        openConfirm={openConfirm}
        quizParam={{
          grade,
          mode,
          type: modeConfig[mode].ableSelectType ? type : undefined,
          level,
          lang,
          duration: modeConfig[mode].ableSelectDuration ? duration : undefined,
          qty: modeConfig[mode].ableSelectQty ? qty : undefined,
          includedKnowledge: modeConfig[mode].ableIncludedKnowledge
            ? includedKnowledge
            : undefined,
          selectedTopic,
          selectedCategory,
          name,
        }}
        topicText={topicText}
        setOpenConfirm={setOpenConfirm}
        selectedTopic={selectedTopic}
      />
      <UserGuide />
      {/* <ConfirmModal
        open={openConfirm}
        quizParam={{
          grade,
          mode,
          type: modeConfig[mode].ableSelectType && type,
          level,
          lang,
          duration,
          qty: modeConfig[mode].ableSelectQty && qty,
          includedKnowledge:
            modeConfig[mode].ableIncludedKnowledge && includedKnowledge,
          selectedTopic,
          selectedCategory,
          name,
        }}
        topicList={fullTopicList}
        handleClose={() => setOpenConfirm(false)}
      /> */}
    </SelectPanelContainer>
  )
}

export default SingleTopic
