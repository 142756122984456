import React, { useState, useEffect } from "react"
import { useStoreState } from "easy-peasy"
import Imgix from "react-imgix"
import Slide from "@material-ui/core/Slide"
import Fade from "@material-ui/core/Fade"
import { navigate } from "@reach/router"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import { Box, Typography, Grid, SvgIcon, Hidden, Snackbar } from "@lib/ui/core"
import Exercise from "@components/Exercise/SingleTopic"
import { getProfile } from "@services/user"

import BannerImage from "@assets/images/banner.png"
import { ReactComponent as SingleTopicIcon } from "@assets/images/single-topic-icon.svg"
import { ReactComponent as ExerciseModeIcon } from "@assets/images/exercise-mode-icon.svg"
import { ReactComponent as TestModeIcon } from "@assets/images/test-mode-icon.svg"
import { ReactComponent as ExamModeIcon } from "@assets/images/exam-mode-icon.svg"

const ExercisePage = () => {
  const [mode, setMode] = useState(null)
  const [notSubscribed, setNotSubscribed] = useState(false)

  const checkIsSubscriber = async () => {
    const response = await getProfile()
    if (
      !response.item ||
      (!response.item.is_subscriber && !response.item.trial_quota)
    ) {
      navigate("/subscription")
      setNotSubscribed(true)
    }
  }

  const resetMode = async () => {
    setMode(null)
  }

  const accessToken = useStoreState(
    ({ localStorage }) => localStorage.auth.accessToken
  )

  useEffect(() => {
    if (!accessToken) navigate("/")
    checkIsSubscriber()
  }, [])

  return (
    <Layout bgcolor={!mode && "#14a1e9"} setMode={setMode} hideFooter={mode}>
      {mode ? (
        <Container>
          <Exercise mode={mode} resetMode={resetMode} setMode={setMode} />
        </Container>
      ) : (
        <Box bgcolor="#14a1e9" py={2}>
          <Hidden xsDown>
            <Box display="flex" justifyContent="center">
              <Typography color="textSecondary" variant="body1">
                選擇模式
              </Typography>
            </Box>
            <Container>
              <Box bgcolor="#ffffff" my={2} p={2} borderRadius="32px">
                <Grid container spacing={1} justify="left">
                  <Grid item xs={4} sm={3}>
                    <Slide direction="up" in>
                      <Box display="flex" justifyContent="center">
                        <Box
                          width="7.5rem"
                          align="center"
                          onClick={() => setMode("single_topic")}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Box py={2}>
                            <SvgIcon
                              component={SingleTopicIcon}
                              fontSize="7.5rem"
                            />
                          </Box>
                          <Box pb={1}>
                            <Typography variant="body1">單一課題</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Slide>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Slide direction="up" in timeout={500}>
                      <Box display="flex" justifyContent="center">
                        <Box
                          width="7.5rem"
                          align="center"
                          onClick={() => setMode("multiple_topic")}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Box py={2}>
                            <SvgIcon
                              component={ExerciseModeIcon}
                              fontSize="7.5rem"
                            />
                          </Box>
                          <Box pb={1}>
                            <Typography variant="body1">綜合課題</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Slide>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Slide direction="up" in timeout={1000}>
                      <Box display="flex" justifyContent="center">
                        <Box
                          width="7.5rem"
                          align="center"
                          onClick={() => setMode("quiz")}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Box py={2}>
                            <SvgIcon
                              component={TestModeIcon}
                              fontSize="7.5rem"
                            />
                          </Box>
                          <Box pb={1}>
                            <Typography variant="body1">測驗模式</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Slide>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Slide direction="up" in timeout={1500}>
                      <Box display="flex" justifyContent="center">
                        <Box
                          width="7.5rem"
                          align="center"
                          onClick={() => setMode("exam")}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Box py={2}>
                            <SvgIcon
                              component={ExamModeIcon}
                              fontSize="7.5rem"
                            />
                          </Box>
                          <Box pb={1}>
                            <Typography variant="body1">考試模式</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Slide>
                  </Grid>
                </Grid>
              </Box>
              <Fade in timeout={1500}>
                <Box>
                  <Imgix src={BannerImage} width="100%" />
                </Box>
              </Fade>
            </Container>
          </Hidden>
          <Hidden smUp implementation="css">
            <Container>
              <Typography>
                感謝你選用Mathod，為得到最佳體驗，我們建議使用桌面電腦或平板電腦。
              </Typography>
            </Container>
          </Hidden>
        </Box>
      )}
      <Snackbar
        open={notSubscribed}
        onClose={() => setNotSubscribed(false)}
        message="需訂閱後使用完整功能，如已進行訂閱，請稍後數分鐘等待系統確認訂閱資訊"
        autoHideDuration={8000}
      />
    </Layout>
  )
}

export default ExercisePage
