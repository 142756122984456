import React, { useEffect, useState } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import Imgix from "react-imgix"
import {
  Box,
  DialogWithCloseButton,
  Checkbox,
  FormControlLabel,
  Typography,
  SvgIcon,
  MenuItem,
  Hidden,
} from "@lib/ui/core"

import Image1 from "@assets/images/userguide-1.jpg"
import Image2 from "@assets/images/userguide-2.jpg"
import Image3 from "@assets/images/userguide-3.jpg"
import Image4 from "@assets/images/userguide-4.jpg"
// import Menu from "../Menu"

import { updateOnboard } from "@services/user"

import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import CircleChecked from "@material-ui/icons/CheckCircleOutline"
import CircleCheckedFilled from "@material-ui/icons/CheckCircle"
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked"

// eslint-disable-next-line arrow-body-style
const UserGuide = () => {
  const [userProfile, setUserProfile] = useState()
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [notShowAgain, setNotShowAgain] = useState(false)
  const renderImage = (number) => {
    switch (number) {
      case 1:
        return <Imgix src={Image1} width="100%" />
      case 2:
        return <Imgix src={Image2} width="100%" />
      case 3:
        return <Imgix src={Image3} width="100%" />
      case 4:
        return <Imgix src={Image4} width="100%" />
      default:
        return null
    }
  }

  const updateReduxProfile = useStoreActions(
    ({ user }) => user.updateReduxProfile
  )

  const profile = useStoreState(({ user }) => user.profile)

  const onFinish = async () => {
    if (notShowAgain) {
      await updateOnboard()
      const updatedProfile = {
        ...userProfile,
        is_onboard: true,
      }
      updateReduxProfile(updatedProfile)
    }
    setOpen(false)
  }

  useEffect(() => {
    if (profile && !profile.is_onboard) {
      setUserProfile(profile)
      setOpen(true)
    }
  }, [])

  return (
    <Box>
      <DialogWithCloseButton
        noCloseButton
        open={open}
        title="用戶指南"
        subTitle={page === 4 ? "練習過程" : "選擇題目"}
      >
        <Box>{renderImage(page)}</Box>
        <Box
          display="flex"
          position="relative"
          alignItems="center"
          justifyContent="center"
          color="#ABABAB"
          my={5}
        >
          {page !== 1 && (
            <Box
              position="absolute"
              left={0}
              onClick={() => setPage(page - 1)}
              style={{ cursor: "pointer" }}
            >
              <SvgIcon
                component={ArrowLeftIcon}
                color="#808080"
                fontSize="80px"
              />
            </Box>
          )}
          <Box fontSize="20px">{page} / 4</Box>
          {page !== 4 && (
            <Box
              position="absolute"
              right={0}
              onClick={() => setPage(page + 1)}
              style={{ cursor: "pointer" }}
            >
              <SvgIcon
                component={ArrowRightIcon}
                color="#808080"
                fontSize="80px"
              />
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box color="#ABABAB">
            <FormControlLabel
              control={
                <Checkbox
                  checked={notShowAgain}
                  onChange={() => setNotShowAgain((prev) => !prev)}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleChecked />}
                  color="#ABABAB"
                />
              }
              label="不再顯示"
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            fontSize="20px"
            color="#007bff"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => onFinish()}
          >
            {page === 4 ? "完成" : "略過"}
          </Box>
        </Box>
      </DialogWithCloseButton>
    </Box>
  )
}

export default UserGuide
