import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  SvgIcon,
  TextField,
  Container,
} from "@lib/ui/core"

import { getTopic } from "@services/exercise"

import { ReactComponent as SingleTopicIcon } from "@assets/images/single-topic-icon.svg"
import { ReactComponent as ExerciseModeIcon } from "@assets/images/exercise-mode-icon.svg"
import { ReactComponent as TestModeIcon } from "@assets/images/test-mode-icon.svg"
import { ReactComponent as ExamModeIcon } from "@assets/images/exam-mode-icon.svg"

import ConfirmModal from "./ConfirmModal"

const BottomAppBar = styled(AppBar)`
  bottom: 0;
  top: auto;
  padding 0 5rem;
`

const StyledButton = styled(Button)`
  background-color: #fff !important;
  padding: 0 1rem;
  border-radius: 14px;
  color: #007bff !important;
`

const StyledTextField = styled(TextField)`
  .MuiFilledInput-root {
    background-color: #fff;
    border-radius: 14px;
  }
  .MuiFilledInput-input {
    padding: 8px 16px;
  }
  .MuiInputBase-input {
    height: 1rem;
    font-size: 14px;
    color: #4d4d4d;
  }
`

const BottomBar = ({
  mode,
  resetMode,
  name,
  setName,
  setMode,
  openConfirm,
  quizParam,
  topicText,
  setOpenConfirm,
  selectedTopic,
}) => {
  const [openSelectMode, setOpenSelectMode] = useState(false)
  const modeText = {
    single_topic: "單一課題",
    multiple_topic: "綜合課題",
    quiz: "測驗模式",
    exam: "考試模式",
  }

  const modeIcon = {
    single_topic: SingleTopicIcon,
    multiple_topic: ExerciseModeIcon,
    quiz: TestModeIcon,
    exam: ExamModeIcon,
  }

  const notSelectedMode = [
    "single_topic",
    "multiple_topic",
    "quiz",
    "exam",
  ].filter((ele) => ele !== mode)
  return (
    <BottomAppBar position="fixed" bgcolor="#2F80ED">
      <Toolbar>
        <Container>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            position="relative"
            py={1}
          >
            <Box p={1} display="flex" alignItems="center" zIndex={1}>
              <StyledTextField
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder="為練習命名"
                width="7.5rem"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              position="absolute"
              py={1}
            >
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  pb={1}
                  minWidth="100px"
                >
                  <Box onMouseLeave={() => setOpenSelectMode(false)}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      position="relative"
                      bottom="20px"
                      marginBottom="-30px"
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenSelectMode((prev) => !prev)}
                      onMouseEnter={() => setOpenSelectMode(true)}
                    >
                      <SvgIcon component={modeIcon[mode]} fontSize="4rem" />
                    </Box>
                    {openSelectMode && (
                      <Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          position="relative"
                          bottom="125px"
                          marginBottom="-125px"
                          style={{ cursor: "pointer" }}
                          onClick={() => setMode(notSelectedMode[1])}
                        >
                          <SvgIcon
                            component={modeIcon[notSelectedMode[1]]}
                            fontSize="4rem"
                          />
                          <Box
                            color="#5D5D5D"
                            position="absolute"
                            top={-30}
                            fontWeight="700"
                          >
                            {modeText[notSelectedMode[1]]}
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="center">
                          <Box
                            position="relative"
                            bottom="30px"
                            marginBottom="-30px"
                            left="100px"
                            style={{ cursor: "pointer" }}
                            onClick={() => setMode(notSelectedMode[0])}
                          >
                            <SvgIcon
                              component={modeIcon[notSelectedMode[0]]}
                              fontSize="4rem"
                            />
                            <Box
                              color="#5D5D5D"
                              position="absolute"
                              left="80px"
                              top="20px"
                              fontWeight="700"
                              whiteSpace="noWrap"
                            >
                              {modeText[notSelectedMode[0]]}
                            </Box>
                          </Box>
                          <Box
                            position="relative"
                            bottom="30px"
                            marginBottom="-30px"
                            right="100px"
                            style={{ cursor: "pointer" }}
                            onClick={() => setMode(notSelectedMode[2])}
                          >
                            <SvgIcon
                              component={modeIcon[notSelectedMode[2]]}
                              fontSize="4rem"
                            />
                            <Box
                              color="#5D5D5D"
                              position="absolute"
                              right="80px"
                              top="20px"
                              fontWeight="700"
                              whiteSpace="noWrap"
                            >
                              {modeText[notSelectedMode[2]]}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box
                  color="#fff"
                  pt={1}
                  pb={2}
                  display="flex"
                  justifyContent="center"
                >
                  {modeText[mode]}
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              width="100%"
              position="absolute"
              justifyContent="flex-end"
            >
              <StyledButton
                onClick={() => setOpenConfirm(true)}
                disabled={selectedTopic.length === 0}
                width="7.5rem"
                height="2rem"
                zIndex={1}
              >
                開始
              </StyledButton>
            </Box>
          </Box>
          <ConfirmModal
            open={openConfirm}
            quizParam={quizParam}
            topicText={topicText}
            handleClose={() => setOpenConfirm(false)}
          />
        </Container>
      </Toolbar>
    </BottomAppBar>
  )
}

export default BottomBar
